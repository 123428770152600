* {
  box-sizing: border-box !important;
}

.tableRow:focus {
  outline-style: solid;
  outline-color: #C3C3C3;
  outline-width: 2px;
}
.tableRow .invitationLink:hover,
.tableRow .invitationLink:focus,
.tableRow .editLink:hover,
.tableRow .editLink:focus {
  outline: none !important;
  color: #2B91C1 !important;
}
.tableRow .editLink:hover svg use,
.tableRow .editLink:focus svg use {
  color: #2B91C1 !important;
}

.rowHeading:hover {
  box-shadow: 1px 1px 5px #B4BABF !important;
}

@supports (clip-path: polygon(100% 0, 0px 0, 0px 100%, 100% 100%)) {
  .envelope--animation {
    display: flex;
    clip-path: polygon(100% 0, 0px 0, 0px 100%, 100% 100%);
    left: 80px !important;
    width: 400px !important;
    pointer-events: none;
    z-index: 1;
  }
  .envelope__container {
    pointer-events: all;
    transform: translateX(-400px);
    transition: transform 500ms ease, visibility 1ms 500ms;
    visibility: hidden;
  }
  .envelope__container--open {
    transform: translateX(0px);
    z-index: auto;
    transition: transform 500ms ease;
    visibility: visible;
  }
}
.envelope-offset-transiton {
  transition: transform 500ms ease;
}

.loading .pinata-icon {
  flex-shrink: 0;
  animation: spin 1.2s linear infinite;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.product-box--banner {
  transform: rotate(-45deg);
  top: 10px;
  left: -55px;
}

.product-box--active-banner {
  transform: rotate(-45deg);
  top: 15px;
  width: 150px;
  left: -50px;
}

.break-long-lines {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.infobox-success {
  background: #D5F0E5;
}

.infobox-warning {
  background: #FADED9;
}

.pinata-checkbox__text,
.pinata-checkbox__text:active,
.pinata-checkbox__text:hover,
.pinata-checkbox__text:focus,
.pinata-checkbox__text:not(:focus) {
  background-color: transparent;
  border-color: transparent;
}

/* SingleCalendar styles for TerminateSubscriptionModal */
@media screen and (max-width: 767px) {
  .SingleDatePicker,
  .SingleDatePickerInput {
    width: 100%;
  }
  .SingleDatePicker_picker, .DayPicker_weekHeaders, .DayPicker_weekHeaders_1 {
    height: 400px;
  }
}
.SingleDatePicker_picker {
  position: relative;
  top: 17px !important;
}

.SingleDatePickerInput,
.DateInput,
.DateInput_input {
  background-color: transparent;
}

.SingleDatePickerInput_calendarIcon .pinata-icon use {
  fill: currentColor;
  color: #B4BABF;
}

.DateInput {
  width: calc(100% - 60px);
}

.SingleDatePickerInput__withBorder {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-color: #B4BABF;
}

@media screen and (max-width: 767px) {
  #modal-content {
    height: 100%;
    padding-left: 0em;
    padding-right: 0em;
  }
}
@media screen and (max-width: 767px) {
  .circle_logo {
    position: absolute;
    right: -48%;
    bottom: -18%;
    transform: rotate(-25deg);
    -ms-transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
  }
}
@media screen and (min-width: 768px) {
  .circle_logo {
    position: absolute;
    right: -27%;
    bottom: 0;
  }
}
.average-activation-degree--text {
  font-size: 150px;
}

